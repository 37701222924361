import React from 'react';
import { Flex, Button, Text, Box, Grid } from 'components';
import { alpha } from '@theme-ui/color';

import { useIntl } from 'context/IntlContext';
import Link from 'components/Link';
import { TabTitle } from '../styled';

const InvestmentTab = () => {
  const { t } = useIntl();
  return (
    <Flex
      sx={{
        justifyContent: 'stretch',
        alignItems: 'center',
        flexDirection: ['column', 'column', 'column', 'row'],
        marginTop: '80px',
        '@media screen and (max-width: 768px)': {
          marginTop: '44px',
        },
      }}
    >
      <Grid
        columns={['1fr', '1fr', '1fr', '1fr 1fr']}
        sx={{
          columnGap: '72px',
          gridRowGap: 0,
        }}
      >
        <Box
          sx={{
            gridColumn: '1',
            gridRow: '1',
            maxWidth: ['100%', '100%', '100%'],
            pr: ['0', '0', '0', '0'],
          }}
        >
          <TabTitle
            sx={{
              mb: [7],
              pt: 0,
            }}
          >
            {t(
              'homepage.home.marketplace_section.investments_subsection.secondary_title',
            )}
          </TabTitle>
          <Text as="p" variant="underHero" sx={{ mb: 9 }}>
            {t(
              'homepage.home.marketplace_section.investments_subsection.subtitle',
            )}
          </Text>
          <Box
            sx={{
              display: 'flex',
              alignItems: ['stretch', 'stretch', 'stretch', 'center'],
              flexDirection: ['column', 'column', 'column', 'row'],
              pb: [3, 3, 3, 0],
            }}
          >
            <Button
              as={Link}
              to={t('global.buttons.signup.link')}
              variant="primary"
              mr={[0, 0, 0, 3]}
              my={2}
            >
              {t('global.buttons.start_investing')}
            </Button>
            <Button
              my={2}
              as={Link}
              to="/marketplace#investments"
              variant="secondary"
            >
              {t('global.buttons.learn_more')}
            </Button>
          </Box>
          <Text
            sx={{
              color: '#233143',
              fontSize: '12px',
              lineHeight: '24px',
              marginTop: '40px',
              textAlign: 'left',
            }}
          >
            {t('marketplace.provider.cta.warning')}
          </Text>
        </Box>
        <Flex
          sx={{
            bg: 'secondary',
            px: 6,
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Flex
            sx={{
              borderBottom: '1px solid',
              borderColor: alpha('quaternary', 0.1),
              py: 5,
              fontSize: 3,
            }}
          >
            <Box
              sx={{
                textAlign: 'center',
                px: [1, 4],
                borderRight: 'solid 1px',
                borderColor: alpha('quaternary', 0.1),
                flexBasis: '50%',
                gridColumn: ['1', '1', '1', '2'],
                gridRow: ['2/3', '2/3', '2/3', '1/3'],
              }}
            >
              <TabTitle>{t('global.product_name.account')}</TabTitle>
              <Text
                sx={{
                  color: alpha('quaternary', 0.5),
                }}
              >
                {t('global.term.no_term')}
              </Text>
            </Box>
            <Box
              sx={{
                textAlign: 'center',
                px: 3,
                color: 'primary',
                flexBasis: '50%',
              }}
            >
              <TabTitle>2.5%</TabTitle>
              <Text>{t('global.aer')}</Text>
            </Box>
          </Flex>
          <Flex
            sx={{
              borderBottom: '1px solid',
              borderColor: alpha('quaternary', 0.1),
              py: 5,

              fontSize: 3,
            }}
          >
            <Box
              sx={{
                textAlign: 'center',
                px: 4,
                borderRight: 'solid 1px',
                borderColor: alpha('quaternary', 0.1),
                flexBasis: '50%',
              }}
            >
              <TabTitle> {t('global.product_name.one')}</TabTitle>
              <Text
                sx={{
                  color: 'primary',
                }}
              >
                {t('global.term.one_year')}
              </Text>
            </Box>
            <Box
              sx={{
                textAlign: 'center',
                px: 3,
                color: 'primary',
                flexBasis: '50%',
              }}
            >
              <TabTitle>3%</TabTitle>
              <Text>{t('global.aer')}</Text>
            </Box>
          </Flex>
          <Flex
            sx={{
              py: 5,
              borderBottom: '1px solid',
              borderColor: alpha('quaternary', 0.1),
              fontSize: 3,
            }}
          >
            <Box
              sx={{
                textAlign: 'center',
                px: 4,
                borderRight: 'solid 1px',
                borderColor: alpha('quaternary', 0.1),
                flexBasis: '50%',
              }}
            >
              <TabTitle> {t('global.product_name.market')}</TabTitle>
              <Text
                sx={{
                  color: 'primary',
                }}
              >
                {t('global.term.three_years')}
              </Text>
            </Box>
            <Box
              sx={{
                textAlign: 'center',
                px: 3,
                color: 'primary',
                flexBasis: '50%',
              }}
            >
              <TabTitle>4%</TabTitle>
              <Text>{t('global.aer')}</Text>
            </Box>
          </Flex>
          <Flex
            sx={{
              py: 5,

              fontSize: 3,
            }}
          >
            <Box
              sx={{
                textAlign: 'center',
                px: 4,
                borderRight: 'solid 1px',
                borderColor: alpha('quaternary', 0.1),
                flexBasis: '50%',
              }}
            >
              <TabTitle> {t('global.product_name.dynamic')}</TabTitle>
              <Text
                sx={{
                  color: 'primary',
                }}
              >
                {t('global.term.five_years')}
              </Text>
            </Box>
            <Box
              sx={{
                textAlign: 'center',
                px: 3,
                color: 'primary',
                flexBasis: '50%',
              }}
            >
              <TabTitle>6%</TabTitle>
              <Text>{t('global.aer')}</Text>
            </Box>
          </Flex>
        </Flex>
      </Grid>
    </Flex>
  );
};

export default InvestmentTab;
